import React from 'react'
import { Redirect } from 'react-router'
import { URLS } from '../../_config'
import Layout from '../layout/Layout'
import { useAuthStatus } from '../hooks/auth/authHook'
import { useAppUserDetailsSelector } from '../hooks/selectors/userSelector'

const requireAuth = (Component: React.ComponentType, role: number = 0) => {
  function AuthHoc(props: any) {
    const isAuth = useAuthStatus()
    const user = useAppUserDetailsSelector()

    /**
     * TODO: add role bases checking here
     */
    return (
      // isAuth && user && user.user_type === role ? <Layout>
      isAuth && user ? <Layout>
        <Component {...props} />
      </Layout> : <Redirect to={URLS.HOME} />
    )
  }

  return AuthHoc
}
export default requireAuth
