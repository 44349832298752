import React, { useEffect } from 'react';
import Sidebar from 'src/components/common/Sidebar';
import RodeoEventPage from 'src/components/events/rodeoEvent/rodeoEventPage';
import { useAppUserDetailsSelector } from 'src/_common/hooks/selectors/userSelector';
import { RODEO_PAGE_NAME } from 'src/_config';
import { APPLICATION_NAME } from 'src/_config';

export default function RodeoEventContainer() {

    const userDetails = useAppUserDetailsSelector()

    useEffect(() => {
        const prevTitle = document.title
        document.title = `${APPLICATION_NAME} : Events`
        return () => {
            document.title = prevTitle
        }
    }, [])

    return (
        <React.Fragment>
            {
                !userDetails ?
                    <div className="logo-wrap">
                        <img src="/images/logo.png" alt="Logo" className="medium-logo" />
                    </div> : null
            }
            <Sidebar page={RODEO_PAGE_NAME.EVENT_LIST} />
            <section className="main-container">
                <RodeoEventPage />
            </section>
        </React.Fragment>
    )
}
