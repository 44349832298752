import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import useSocket from 'use-socket.io-client';
import { useAppEvenListSelector, useAppEventPerformanceListSelector, useAppRodeoEventLoginRequiredSelector } from 'src/_common/hooks/selectors/eventSelector';
import { CRYPTO_SECRET_KEY, getEventStatusText, URLS, SOCKET_URL, SOCKET_CHANNEL, INSIDERODEO_EVENT_STORAGE, CALFSCRAMBLE_NEXT_GAME_IDS } from 'src/_config';
import { useEventApi } from 'src/_common/hooks/actions/event/appEventApiHook';
import { toast } from 'react-toastify';
import { useAppEventAction } from 'src/_common/hooks/actions/event/appEventActionHook';
import { useAppGuestUserIdSelector, useAppUserDetailsSelector } from 'src/_common/hooks/selectors/userSelector';
import clsx from 'clsx';
import { useAppUserAction } from 'src/_common/hooks/actions/user/appUserActionHook';
import { Console } from 'console';
const Cryptr = require('cryptr');
const cryptr = new Cryptr(CRYPTO_SECRET_KEY);

export default function RodeoPerformancePage() {

    const eventApi = useEventApi()
    const history = useHistory()
    const eventAction = useAppEventAction()
    const userDetails = useAppUserDetailsSelector()   
    const eventPerformanceListSelector = useAppEventPerformanceListSelector()
    const [loginReq, setLoginReq] = useState<any>(0)
    const { eventId } = useParams<any>();
    const e_id: number = parseInt(cryptr.decrypt(eventId));
    const eventListSelector = useAppEvenListSelector()
    const guestUserId = useAppGuestUserIdSelector()
    const userAction = useAppUserAction()
    
    const isInsideRodeoTokenExists = localStorage.getItem(INSIDERODEO_EVENT_STORAGE)

    const [socket] = useSocket(SOCKET_URL, {
        autoConnect: false,
    });

    // useEffect(() => {

    //     if (userDetails) {
    //         socket.connect();

    //         //Status Change Socket
    //         socket.on(SOCKET_CHANNEL.START_EVENT, (changeEventStatus: any) => {
    //             console.log(changeEventStatus)
    //             // eventAction.pushGameStatusChangeSocketDataIntoGameList(changeEventStatus, userDetails.id)
    //         });

    //         //Status Message Change Socket
    //         // socket.on(SOCKET_CHANNEL.STATUS_MESSAGE, (statusMessage: any) => {
    //         //     eventAction.pushGameStatusMessageSocketDataIntoStatusList(statusMessage, userDetails.id)
    //         // });
    //     }

    //     return () => {
    //         socket.disconnect()
    //     }
    // }, [])

    useEffect(() => {
  
        socket.connect();
  
        socket.on(SOCKET_CHANNEL.START_EVENT, (changeEventStatus: any) => {
            if(changeEventStatus && changeEventStatus.rodeo_event_id && guestUserId)
            {
              console.log(changeEventStatus.rodeo_event_id)
                // setShowGuestSaveVoteModal(true)
            }
        })
  
        return () => {
            socket.disconnect()
        }
    },[socket])

    const getEventPerformanceList = () => {
        const params:any = {
            rodeo_event_id: e_id,
            game_id: eventListSelector?.game_details.id,
        }
        if(!userDetails)
        {
            if(guestUserId)
            {
                params['guest_user_id']=guestUserId
            }
        }
        eventApi.callGetEventPerformanceList(params, (message: string, resp: any) => {
            if (resp) {
                // eventAction.updateLoginRequired(resp.rodeo_event_dtls.login_required)
                console.log(resp)
                userAction.guestUserId(resp)
                setLoginReq(resp.rodeo_event_dtls.login_required)
            }
        }, (message: string) => {
            toast.error(message)
        })
    }

    const goToContestantPage = (e: any, game: number) => {
        e.preventDefault();
        const gameId = cryptr.encrypt(game)
        history.push(`/event/${eventId}/${gameId}`)
    }

    useEffect(() => {
        getEventPerformanceList()
    }, [])

    useEffect(() => {
        if (userDetails || loginReq==0)
        {
            history.push(`/event/${eventId}`)
        }
        else
        {
            history.push(`${URLS.LOGIN}`)
        }
    }, [loginReq])

    useEffect(() => {
        console.log(eventPerformanceListSelector)
        // if (eventPerformanceListSelector && eventPerformanceListSelector.length) {
        //     localStorage.setItem(CALFSCRAMBLE_NEXT_GAME_IDS, eventPerformanceListSelector.map((x: any) => JSON.stringify(x.id)))
        // }
    }, [eventPerformanceListSelector])

    // const goToLeaderBoardPage = (e: any) => {
    //     e.preventDefault()
    //     history.push(`/result/${eventId}`)
    // }

    return (
        <React.Fragment>
            <h2 className="top-two-btns has-leaderboard">
                {
                    !isInsideRodeoTokenExists ?
                        <Link to={URLS.HOME} className="back-history">
                            <img src="/images/black-left.svg" alt="back" />
                        </Link> : null
                }
                <h2 className="page-title text-left">
                    <a className="back-history">
                        <span>List Of </span>Performances
                    </a>    
                </h2>
                {/* <span className="page-title text-left">List of Performances</span> */}

                {/* <Link to="#" className="dual-btn" onClick={(e) => goToLeaderBoardPage(e)}>
                    Results
                </Link> */}
            </h2>
            <hr className="color-white" />
            {/* <div className="ready-msg-wrap">
                Tap your favorite to win the performance
            </div> */}
            <div className="top-txt">
                <p>Pick your favorite to win the performance</p>
            </div>
            <ul className="list-style">
                {
                    eventPerformanceListSelector && eventPerformanceListSelector.length ?
                        eventPerformanceListSelector.map((event: any) => (
                            <li key={event.id} className={
                                clsx({
                                    // "voted": event.ispicked
                                })
                            }>
                                <Link to="" className={
                                    clsx({
                                        "ripple-effect not-started-yet d-flex": event.status === 0,
                                        "ripple-effect on-going-game d-flex": event.status === 1,
                                        "ripple-effect event-end d-flex": event.status === 2
                                    })
                                } onClick={(e) => goToContestantPage(e, event.id)} >
                                    {/* <em>
                                        {
                                            getEventStatusText(event.status)
                                        }
                                    </em> */}
                                    <span className="r-event-img">
                                        <img src={event && event.avatar ? event.avatar.original : '/images/no-image.png'} alt={`event-${event.title}`} />
                                    </span>
                                    <span className="r-event-info">
                                        <span className="d-inline-block r-event-title">{event.title}</span>
                                    </span>
                                    {
                                        // event.sponsor && event.sponsor.sponsor && event.sponsor.sponsor.original ?
                                        //     <span className="sponser-img">
                                        //         <img src={event.sponsor && event.sponsor.sponsor && event.sponsor.sponsor.original} alt={`sponsor-${event.sponsor.title}`} />
                                        //     </span> : null
                                    }

                                </Link>
                            </li>
                        )) :
                        <div className="no-text text-white text-center">
                            No performances available
                        </div>
                }
            </ul>
        </React.Fragment>
    )
}