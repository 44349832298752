import React from 'react';
import { Modal } from 'react-bootstrap';

interface ContestantSelectionModalProps {
    onSuccess: (success: any) => void;
    onClose: (success: any) => void;
    shouldShow: boolean;
    contestantDetails: any;
}


export default function ContestantSelectionModal({ onSuccess, onClose, shouldShow, contestantDetails }: ContestantSelectionModalProps) {

    return (
        <React.Fragment>
            <Modal
                show={shouldShow}
                backdrop="static"
                keyboard={false}
                className="dark-modal"
                centered
                contentClassName='custom-modal'
            >
                <Modal.Header>
                    <h2 className="modal-header-text">Pick your Contestant</h2>
                    <button type="button" className="btn-close" onClick={() => onClose(true)}></button>
                </Modal.Header>
                <Modal.Body bsPrefix={'main-container'}>
                    <div className="modal-body">
                        <h4 className="rider-name-content">
                            <p>
                                You selected <strong>{contestantDetails.batch_no} {contestantDetails.contestant_name?'('+contestantDetails.contestant_name+')':''}</strong>
                            </p>
                            <p>
                                Tap ok to confirm, once confirmed cannot be changed again!
                            </p>
                        </h4>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="btn-wrap mb-3">
                        <button type="button"
                            className="rkmd-btn btn-lg btn-orange ripple-effect w-100 mt-3"
                            onClick={() => onSuccess(true)}>
                            Ok
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    )

}