import { call } from 'redux-saga/effects';
import { API_URL } from '../_config';
import { CallApi } from './api/callApi';

export function* getCmsContent(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.CMS.GET_CMS, data);
        if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
} 