import React from 'react';
import { Modal } from 'react-bootstrap';
import { trimTo } from 'src/_config';
import { useAppEventAction } from 'src/_common/hooks/actions/event/appEventActionHook';
import { useAppGameWiningTruckDetailsSelector } from 'src/_common/hooks/selectors/eventSelector';
interface CompleteContestantModalProps {
    shouldShow: boolean;
    currentContestant: any;
}

export default function CompleteContestantModal({ shouldShow, currentContestant }: CompleteContestantModalProps) {

    return (
        <React.Fragment>
            <Modal
                show={shouldShow}
                backdrop="static"
                size="lg"
                // onHide={() => onClose()}
                keyboard={false}
                className="dark-modal"
                centered
                contentClassName='answer-modal'
            >
               
                <Modal.Body>
                    <div className="modal-body">
                    <h2 className="modal-header-text">Completed Contestant </h2> <span className="fs-15">{currentContestant.batch_no?currentContestant.batch_no:null} {currentContestant.contestant_name?'('+currentContestant.contestant_name+')':null}
                    </span></div>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    )
}