import React, { useEffect } from 'react';
import Sidebar from 'src/components/common/Sidebar';
import LegalPage from 'src/components/cms/legal/legalPage';
import { APPLICATION_NAME, RODEO_PAGE_NAME } from 'src/_config';

export default function LegalContainer() {

    useEffect(() => {
        const prevTitle = document.title
        document.title = `${APPLICATION_NAME} : Legal`
        return () => {
            document.title = prevTitle
        }
    }, [])

    return (
        <React.Fragment>
            <Sidebar page={RODEO_PAGE_NAME.LEGAL} />
            <section className="main-container">
                <LegalPage />
            </section>
        </React.Fragment>
    )
}