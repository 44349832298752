import { useDispatch } from 'react-redux'
import { ACTIONS } from 'src/_config'

export function useAppEventAction() {

  const dispatch = useDispatch()

  const pushGameStatusChangeSocketDataIntoGameList = (socketData: any, userId: number) => {
    var params = {
      socketData: socketData,
      userId: userId,
    }
    dispatch({
      type: ACTIONS.EVENT.GET_SOCKET_START_DATA_AND_PUSH_TO_GAME_LIST,
      payload: params
    })
  }

  const pushGameStatusChangeSocketDataIntoGameDetails = (socketData: any, userId: number) => {
    var params = {
      socketData: socketData,
      userId: userId,
    }
    dispatch({
      type: ACTIONS.EVENT.GET_SOCKET_START_DATA_AND_PUSH_TO_GAME_DETAILS,
      payload: params
    })
  }

  const pushGameStatusMessageSocketDataIntoStatusList = (socketData: any, userId: number) => {
    var params = {
      socketData: socketData,
      userId: userId,
    }
    dispatch({
      type: ACTIONS.EVENT.GET_SOCKET_STATUS_MESSAGE_AND_PUSH_TO_STATUS_LIST,
      payload: params
    })
  }

  const pushRiderWithVoteDetailsSocketDataIntoRiderList = (socketData: any) => {
    var params = {
      socketData: socketData,
    }
    dispatch({
      type: ACTIONS.EVENT.GET_SOCKET_RIDER_WITH_VOTE_DETAILS,
      payload: params
    })
  }

  const pushWiningTruckDetailsSocketDataIntoWiningDetails = (socketData: any, userId: number) => {
    var params = {
      socketData: socketData,
      userId: userId,
    }
    dispatch({
      type: ACTIONS.EVENT.GET_WINING_SOCKET_DATA_AND_PUSH_TO_WINING_DETAILS,
      payload: params
    })
  }

  const showWiningTruckDetailsModal = (isOpen: boolean) => {
    dispatch({
      type: ACTIONS.EVENT.OPEN_WINING_MODAL,
      payload: isOpen,
    })
  }

  const emptyGameDetails = () => {
    dispatch({
      type: ACTIONS.EVENT.GET_EVENT_CONTESTANTS,
      payload: null,
    })
  }

  const fromRouteHandler = (formRoute: any) => {
    dispatch({
      type: ACTIONS.EVENT.NEXT_EVENT_FROM_ROUTE,
      payload: formRoute,
    })
  }

  const updateEventGlowingStatus = (socketData: any) => {
    var params = { socketData }
    dispatch({
      type: ACTIONS.EVENT.GET_EVENT_LIST_GLOWING_SOCKET,
      payload: params
    })
  }

  const updateLoginRequired = (loginR: number) => {
    dispatch({
      type: ACTIONS.EVENT.RODEO_EVENT_LOGIN_REQUIRED,
      payload: loginR,
    })
  }

  const resetPickIds = () => {
    dispatch({
      type: ACTIONS.EVENT.RESET_PICK_ID,
    })
  }

  return {
    pushGameStatusChangeSocketDataIntoGameList,
    pushGameStatusChangeSocketDataIntoGameDetails,
    pushGameStatusMessageSocketDataIntoStatusList,
    pushRiderWithVoteDetailsSocketDataIntoRiderList,
    pushWiningTruckDetailsSocketDataIntoWiningDetails,
    fromRouteHandler,
    emptyGameDetails,
    updateEventGlowingStatus,
    showWiningTruckDetailsModal,
    updateLoginRequired,
    resetPickIds,
  }
}