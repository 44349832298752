import React, { useEffect } from 'react';
import Sidebar from 'src/components/common/Sidebar';
import AboutUsPage from 'src/components/cms/aboutUs/aboutUsPage';
import { APPLICATION_NAME, RODEO_PAGE_NAME } from 'src/_config';

export default function AboutUsContainer() {

    useEffect(() => {
        const prevTitle = document.title
        document.title = `${APPLICATION_NAME} : About Us`
        return () => {
            document.title = prevTitle
        }
    }, [])

    return (
        <React.Fragment>
            <Sidebar page={RODEO_PAGE_NAME.ABOUT_US} />
            <section className="main-container">
                <AboutUsPage />
            </section>
        </React.Fragment>
    )
}