import React from 'react';
import { Modal } from 'react-bootstrap';

interface GuestSaveSelectionModalProps {
    onSuccess: (success: any) => void;
    onClose: (success: any) => void;
    shouldShow: boolean;
}


export default function GuestSaveSelectionModal({ onSuccess, onClose, shouldShow }: GuestSaveSelectionModalProps) {

    return (
        <React.Fragment>
            <Modal
                show={shouldShow}
                backdrop="static"
                keyboard={false}
                className="dark-modal"
                centered
                contentClassName='custom-modal'
            >
                <Modal.Header>
                    <h2 className="modal-header-text">Save Your Selection</h2>
                    <button type="button" className="btn-close" onClick={() => onClose(true)}></button>
                </Modal.Header>
                <Modal.Body bsPrefix={'main-container'}>
                    <div className="modal-body">
                        <h4 className="rider-name-content">
                            <p>
                                Are you want to save your selection? Please login first to save or you can skip
                            </p>
                        </h4>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="btn-wrap mb-3">
                        <button type="button"
                            className="rkmd-btn btn-lg btn-red ripple-effect w-100 mt-3"
                            onClick={() => onClose(true)}>
                            Skip
                        </button>
                    </div>
                    <div className="btn-wrap mb-3">
                        <button type="button"
                            className="rkmd-btn btn-lg btn-orange ripple-effect w-100 mt-3"
                            onClick={() => onSuccess(true)}>
                            Save
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    )

}