import React, { useEffect, useState } from "react";
import Marquee from "react-fast-marquee";
import { useParams } from "react-router";
import { useEventApi } from "src/_common/hooks/actions/event/appEventApiHook";
import { useAppTvImageSelector } from "src/_common/hooks/selectors/eventSelector";
import { removeEmptyObjectKey, removeTrailingZero, SOCKET_CHANNEL, SOCKET_URL } from "src/_config";
import useSocket from 'use-socket.io-client';
import CompleteContestantModal from "../events/modal/completeContestantModal";

export function RodeoTvImageComponent() {

    const [isInitialLoad, setIsInitialLoad] = useState<boolean>(false)
    const [isShowingAdsPromotion, setIsShowingAdsPromotion] = useState<boolean>(false)
    const [tvImageSelector, settvImageSelector] = useState<any>(null)
    const [addPromotionText, setAddPromotionText] = useState<any>("")
    const [adPromotionImage, setAdPromotionImage] = useState<any>(null)
    const [showCompleteContestantModal, setShowCompleteContestantModal] = useState<boolean>(false)
    const [currentContestant, setCurrentContestant] = useState<any>(null)
    
    const resetCssStyle = () => {
        var element = document.getElementById('rodeo_body');
        if (element) {
            element.style.padding = '25px 19px 0 19px'
        }
    }

    //Socket Implementation start here
    const [socket] = useSocket(SOCKET_URL, {
        autoConnect: false,
    });

    useEffect(() => {
        if(showCompleteContestantModal) {
            setTimeout(() => {
                setShowCompleteContestantModal(false)
            }, 4000);
        }
    }, [showCompleteContestantModal])

    useEffect(() => {
        socket.connect()
        socket.on(SOCKET_CHANNEL.TV_BOARD_IMAGE, (tvImageData: any) => {
            console.log(tvImageData)
            if (tvImageData.type == "landing_screen") {
                resetCssStyle()
                setIsInitialLoad(true)
            }
           
        });

        socket.on(SOCKET_CHANNEL.COMPLETE_CURRENT_CONTESTANT, (data: any) => {
            console.log(data)
            setCurrentContestant(data)
            setShowCompleteContestantModal(true)
        })

        socket.on(SOCKET_CHANNEL.COMPLETE_RIDE, (adsPromotionData: any) => {
            console.log(adsPromotionData)
            // settvImageSelector(adsPromotionData)
            // setAddPromotionText('Calf Scramble')
            setAddPromotionText('www.insiderodeo.com')
            resetCssStyle()
            setIsInitialLoad(false)

            setIsShowingAdsPromotion(true)
            settvImageSelector(adsPromotionData)
        });

        socket.on(SOCKET_CHANNEL.AD_PROMOTION, (adsPromotionData: any) => {
            console.log(adsPromotionData)
            setIsInitialLoad(false)
            // setAddPromotionText(adsPromotionData.text?adsPromotionData.text:'Calf Scramble')
            setAddPromotionText(adsPromotionData.text?adsPromotionData.text:'www.insiderodeo.com')
            setIsShowingAdsPromotion(false)
            setAdPromotionImage(adsPromotionData.image)
        });


        return () => {
            socket.disconnect()
        }
    }, [socket])

    
    useEffect(()=> {
        console.log(tvImageSelector)
    },[tvImageSelector])

    useEffect(() => {
        setIsInitialLoad(true)
        var element = document.getElementById('rodeo_body');
        if (element) {
            element.style.paddingTop = '0px'
        }
    }, [])

    return (
        <React.Fragment>
            {
                isInitialLoad ?
                    <div className="main-bg"
                        style={{
                            backgroundImage: `url("${'/images/tv/tv-screen-blank.jpg'}")`,
                            position: 'fixed',
                            width: '100%',
                            height: '100%',
                            backgroundSize: '100% 100%',
                            backgroundRepeat: 'no-repeat'
                        }}
                    >  
                        
                    </div> :
                   <div className="bg">
                        
                        <div className="w-100">
                            {addPromotionText ?
                                <div className="logobar ">
                                    <div className="border-div" />
                                    <div className="border-div2" />
                                    <div className="logo_left_txt">                                       
                                        {
                                            addPromotionText
                                        }
                                    </div>
                                    <div className="logo_image"><img src="/images/tv/logo.png" className="img-responsive" /></div>
                                </div>
                                :
                                <div className="logo_image_no_text"><img src="/images/tv/logo.png" className="img-responsive" /></div>
                            }
                            <div className="heading_text"></div>
                            <div className="clearfix">
                            {
                                isShowingAdsPromotion ?
                                <ul className="tv-list">
                                    {
                                        // tvImageSelector && tvImageSelector.length ?
                                        tvImageSelector && tvImageSelector.list && tvImageSelector.list.length ?
                                            tvImageSelector.list.map((item: any) => (
                                               
                                                <li key={item.id} >
                                                    <div className={item.is_complete?"completed-ride left_sec":"left_sec"}>
                                                        <div className="border-div"></div>
                                                        <div className="curve-bg">
                                                            <div className="left_sec_l">{item.batch_no?item.batch_no:null} {item.contestant_name?'('+item.contestant_name+')':null}</div>
                                                            <div className="left_sec_r">{item.complete_duration?"Completed In "+item.complete_duration:"--"} </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            )) : <div className="text-center" style={{ color: '#fff' }}>
                                                <p> No Result publish yet!</p>
                                            </div>
                                    }
                                </ul>
                                :
                                <div className="tv-ads-promotion">
                                    <img className="w-100" src={adPromotionImage} alt="image" />
                                </div>
                            }    
                            </div>
                           
                        </div> 
                    </div>
            }
            {
                showCompleteContestantModal &&
                <CompleteContestantModal
                    shouldShow={showCompleteContestantModal}
                    currentContestant={currentContestant?.completeContestant}
                />
            }


        </React.Fragment>
    )
}