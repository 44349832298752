import React, { useEffect } from 'react';
import { APPLICATION_NAME, RODEO_PAGE_NAME } from 'src/_config';
import HowToPlayPage from 'src/components/cms/howToPlay/howToPlayPage';
import Sidebar from 'src/components/common/Sidebar';

export default function HowToPlayContainer() {

    useEffect(() => {
        const prevTitle = document.title
        document.title = `${APPLICATION_NAME} : How To Play`
        return () => {
            document.title = prevTitle
        }
    }, [])

    return (
        <React.Fragment>
            <Sidebar page={RODEO_PAGE_NAME.HOW_TO_PLAY} />
            <section className="main-container">
                <HowToPlayPage />
            </section>
        </React.Fragment>
    )
}