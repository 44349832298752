import React, { useEffect } from 'react';
import { APPLICATION_NAME } from 'src/_config';
import { RodeoTvImageComponent } from 'src/components/tv-image/tv-image-page';

export default function RodeoTvImageContainer() {

    useEffect(() => {
        const prevTitle = document.title
        document.title = `${APPLICATION_NAME} : TV Leaderboard`
        return () => {
            document.title = prevTitle
        }
    }, [])

    return (
        <React.Fragment>
            <RodeoTvImageComponent />
        </React.Fragment>
    )
}
