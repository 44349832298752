import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { EventGameDetails } from 'src/_common/interfaces/models/event';
import { StateExtended } from '../../interfaces/StateExtended'

export function useAppEvenListSelector() {
    const eventList = useSelector((state: StateExtended) => state.event.getEventList)
    return eventList;
}

export function useAppEventPerformanceListSelector() {
    const eventPerformanceList = useSelector((state: StateExtended) => state.event.getEventPerformanceList)
    return eventPerformanceList;
}

export function useAppEventContestantListSelector() {
    const eventContestantList = useSelector((state: StateExtended) => state.event.getEventContestantList)
    return eventContestantList;
}

export function useAppEventGameDetailSelector() {
    const eventGameDetails = useSelector((state: StateExtended) => state.event.getEventGameDetails)
    return eventGameDetails;
}

export function useAppEventStatusMessageSelector() {
    const statusMessage = useSelector((state: StateExtended) => state.event.getEventStatusMessage)
    return statusMessage;
}


export function useAppEventLeaderBoardSelector() {
    const leaderBoard = useSelector((state: StateExtended) => state.event.getLeaderBoard)
    return leaderBoard;
}

export function useAppTvImageSelector() {
    const tvImage = useSelector((state: StateExtended) => state.event.getTvImageDetails)
    return tvImage;
}

export function useAppGameWiningTruckDetailsSelector() {
    const winingTruck = useSelector((state: StateExtended) => state.event.getWiningTruckDetails)
    return winingTruck;
}

export function useAppWiningTruckModalOpen() {
    const winingModal = useSelector((state: StateExtended) => state.event.winingTruckModalShow)
    return winingModal;
}
export const useAppNextEventRouteSelector = () => {
    const activeRoute = useSelector((state: StateExtended) => state.event.fromRoute)
    return activeRoute;
}
export const useAppPickIdsSelector = () => {
    const pick_id = useSelector((state: StateExtended) => state.event.pick_id)
    return pick_id;
}
export const useAppRodeoEventLoginRequiredSelector = () => {
    const login_required = useSelector((state: StateExtended) => state.event.login_required)
    return login_required;
}