import React, { useEffect} from 'react';
import Sidebar from 'src/components/common/Sidebar';
import RodeoPerformancePage from 'src/components/events/rodeoPerformance/rodeoPerformancePage';
import { APPLICATION_NAME, RODEO_PAGE_NAME } from 'src/_config';

export default function RodeoPerformanceContainer() {

    useEffect(() => {
        const prevTitle = document.title
        document.title = `${APPLICATION_NAME} : Performance List`
        return () => {
          document.title = prevTitle
        }
      }, [])

    return (
        <React.Fragment>
            <Sidebar page={RODEO_PAGE_NAME.EVENT_GAME_LIST} />
            <section className="main-container">
                <RodeoPerformancePage />
            </section>
        </React.Fragment>
    )
}
