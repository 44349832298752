import { SAGA_ACTIONS } from 'src/_config'
import { GetEventList, GetEventPerformanceList, GetEventGameDetails, GetUpcomingEventList, JoinEventGame, LeaderBoard, TvImageDetails, SaveVote, GetEventContestantList, PickContestant } from 'src/_common/interfaces/ApiReqRes'
import { useApiCall } from '../common/appApiCallHook'

export function useEventApi() {

    const callApi = useApiCall()

    const eventList = (data: GetEventList, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.EVENT.GET_EVENT_LIST, data, onSuccess, onError);
    }

    const eventPerformanceList = (data: GetEventPerformanceList, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.EVENT.GET_EVENT_PERFORMANCE, data, onSuccess, onError);
    }

    const eventContestantList = (data: GetEventContestantList, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.EVENT.GET_EVENT_CONTESTANTS, data, onSuccess, onError);
    }

    // const upcomingEventList = (data: GetUpcomingEventList, onSuccess: Function, onError: Function) => {
    //     callApi(SAGA_ACTIONS.EVENT.GET_UPCOMING_EVENT, data, onSuccess, onError);
    // }

    const pickContestant = (data: PickContestant, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.EVENT.PICK_CONTESTANT, data, onSuccess, onError);
    }

    // const viewLeaderBoard = (data: LeaderBoard, onSuccess: Function, onError: Function) => {
    //     callApi(SAGA_ACTIONS.EVENT.LEADERBOARD, data, onSuccess, onError);
    // }

    // const joinEventGame = (data: JoinEventGame, onSuccess: Function, onError: Function) => {
    //     callApi(SAGA_ACTIONS.EVENT.JOIN_EVENT_GAME, data, onSuccess, onError);
    // }

    // const tvImageList = (data: TvImageDetails, onSuccess: Function, onError: Function) => {
    //     callApi(SAGA_ACTIONS.TV_IMAGE.DETAILS, data, onSuccess, onError, false);
    // }

    const saveVote = (data: SaveVote, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.EVENT.SAVE_VOTE, data, onSuccess, onError);
    }

    return {
        callGetEventList: eventList,
        callGetEventPerformanceList: eventPerformanceList,
        callEventContestantList: eventContestantList,
        // callGetUpcomingEventList: upcomingEventList,
        // callGetEventGameDetails: eventGameDetails,
        callPickContestant: pickContestant,
        // callViewLeaderBoard: viewLeaderBoard,
        // callTvImageDetails: tvImageList,
        // callJoinEventGame: joinEventGame,
        callSaveVote: saveVote,
    }
}