import { ActionExtended } from 'src/_common/interfaces/ActionExtended';
import { ACTIONS } from 'src/_config'
export interface EventReducer {
    getEventList: any;
    getEventPerformanceList: any;
    getEventContestantList: any;
    getEventGameDetails: any;
    getEventStatusMessage: any;
    getLeaderBoard: any;
    getTvImageDetails: any;
    getWiningTruckDetails: any;
    winingTruckModalShow: boolean;
    fromRoute: any;
    pick_id: any;
    login_required: number;
}

const initialState: EventReducer = {
    getEventList: null,
    getEventPerformanceList: null,
    getEventContestantList: null,
    getEventGameDetails: null,
    getEventStatusMessage: null,
    getLeaderBoard: null,
    getTvImageDetails: null,
    getWiningTruckDetails: null,
    winingTruckModalShow: false,
    fromRoute: null,
    pick_id: null,
    login_required: 0,
}

const eventReducer = (state = initialState, action: ActionExtended) => {
    switch (action.type) {
        case ACTIONS.EVENT.GET_EVENT_LIST:
            return {
                ...state,
                getEventList: action.payload
            }
        case ACTIONS.EVENT.GET_EVENT_PERFORMANCE:
            return {
                ...state,
                getEventPerformanceList: action.payload
            }
        case ACTIONS.EVENT.GET_EVENT_CONTESTANTS:
            return {
                ...state,
                getEventContestantList: action.payload
            }
        case ACTIONS.EVENT.GET_STATUS_MESSAGE:
            return {
                ...state,
                getEventStatusMessage: action.payload
            }
        case ACTIONS.EVENT.LEADERBOARD:
            return {
                ...state,
                getLeaderBoard: action.payload
            }
        case ACTIONS.TV_IMAGE.DETAILS:
            return {
                ...state,
                getTvImageDetails: action.payload
            }
        case ACTIONS.EVENT.OPEN_WINING_MODAL:
            return {
                ...state,
                winingTruckModalShow: action.payload
            }
        case ACTIONS.EVENT.NEXT_EVENT_FROM_ROUTE:
            return {
                ...state,
                fromRoute: action.payload
            }
        case ACTIONS.EVENT.SET_PICK_ID:
            // if(action.payload.length)
            let pickId=[]
            pickId=state.pick_id
            if(pickId)
            {
                pickId.push(action.payload)
            }
            else
            {
                pickId=[action.payload]
            }
            console.log(pickId)
            return {
                ...state,
                pick_id: pickId
            }
        case ACTIONS.EVENT.RESET_PICK_ID:
            return {
                ...state,
                pick_id: null
            }
        case ACTIONS.EVENT.RODEO_EVENT_LOGIN_REQUIRED:
            return {
                ...state,
                login_required: action.payload
            }

        //Update rider vote details
        case ACTIONS.EVENT.GET_SOCKET_RIDER_WITH_VOTE_DETAILS:

            var { socketData } = action.payload;
            let gameDetailsForRider = state.getEventGameDetails;
            if (gameDetailsForRider && gameDetailsForRider.events && gameDetailsForRider.events.id === socketData.rodeo_event_event_id) {
                gameDetailsForRider.events.riders = socketData.riders
            }

            return {
                ...state,
                getEventGameDetails: {
                    ...gameDetailsForRider
                }
            }

        // Update Game Status at Event game list page
        case ACTIONS.EVENT.GET_SOCKET_START_DATA_AND_PUSH_TO_GAME_LIST:
            var { socketData } = action.payload;
            let eventList = state.getEventPerformanceList;
            if (eventList && eventList.length) {
                var findGameIndex = eventList.findIndex((x: any) => x.id === socketData.id)
                if (findGameIndex >= 0) {
                    eventList[findGameIndex].status = socketData.status
                }
            }

            return {
                ...state,
                getEventPerformanceList: eventList && eventList.length ? eventList.map((x: any) => Object.assign(x)) : []
            }

        //Update game event details data
        case ACTIONS.EVENT.GET_SOCKET_START_DATA_AND_PUSH_TO_GAME_DETAILS:
            var { socketData } = action.payload;
            let gameDetails = state.getEventGameDetails;
            if (gameDetails && gameDetails.events && gameDetails.events.id == socketData.id) {
                gameDetails.events.status = socketData.status
            }
            return {
                ...state,
                getEventGameDetails: {
                    ...gameDetails
                }
            }

        //Update game status message
        case ACTIONS.EVENT.GET_SOCKET_STATUS_MESSAGE_AND_PUSH_TO_STATUS_LIST:
            var { socketData } = action.payload;
            let statusMessageList = state.getEventStatusMessage;
            if (statusMessageList && statusMessageList.length) {
                statusMessageList.map((x: any, index: number) => {
                    if (x.rodeo_event_id === socketData.rodeo_event_id) {
                        statusMessageList[index].status_text = socketData.status_text
                    }
                })
            } else {
                statusMessageList.push(socketData)
            }
            return {
                ...state,
                getEventStatusMessage: statusMessageList && statusMessageList.length ? statusMessageList.map((x: any) => Object.assign(x)) : []
            }

        //Update wining truck details
        case ACTIONS.EVENT.GET_WINING_SOCKET_DATA_AND_PUSH_TO_WINING_DETAILS:
            var { socketData } = action.payload;
            let gameDetailsPage = state.getEventGameDetails;
            let winingData = null;
            let isWiningModalShow: boolean = false;
            if (socketData && socketData.event_dtls) { // If socket data && inside data event details exist
                if (gameDetailsPage && gameDetailsPage.event_dtls && gameDetailsPage.event_dtls.isjoined) { // If current user joined that game
                    if (gameDetailsPage && gameDetailsPage.event_dtls && gameDetailsPage.event_dtls.id === socketData.event_dtls.id) { //if socket game id equals to user stay on page game id
                        if (gameDetailsPage.event_dtls.status === 2 && socketData.event_dtls.status === 2) { // if game status is end, 0 => Not started, 1=> Ongoing, 2=> End
                            winingData = socketData;
                            isWiningModalShow = true;
                            gameDetailsPage.event_dtls.winning = { //Update Wining Reducer data
                                event_id: socketData.event_dtls.id,
                                rodeo_event_id: socketData.event_dtls.rodeo_event_id,
                                truck_id: socketData.truck.id
                            };
                        }
                    }
                } else {
                    if (gameDetailsPage && gameDetailsPage.event_dtls && !gameDetailsPage.event_dtls.isjoined) { // If current user not joined that game
                        if (gameDetailsPage && gameDetailsPage.event_dtls && gameDetailsPage.event_dtls.id === socketData.event_dtls.id) { //if socket game id equals to user stay on page game id
                            if (gameDetailsPage.event_dtls.status === 2 && socketData.event_dtls.status === 2) { // if game status is end, 0 => Not started, 1=> Ongoing, 2=> End
                                gameDetailsPage.event_dtls.winning = { //Update Wining Reducer data
                                    event_id: socketData.event_dtls.id,
                                    rodeo_event_id: socketData.event_dtls.rodeo_event_id,
                                    truck_id: socketData.truck.id
                                };
                            }
                        }
                    }
                }
            }
            return {
                ...state,
                getWiningTruckDetails: winingData ? { ...winingData } : null,
                winingTruckModalShow: isWiningModalShow ? isWiningModalShow : false,
                getEventGameDetails: {
                    ...gameDetailsPage
                }
            }

        case ACTIONS.EVENT.GET_EVENT_LIST_GLOWING_SOCKET:
            var { socketData } = action.payload;
            let getEventListData = state.getEventList;
            if (getEventListData && getEventListData.length) {
                var findEventIndex = getEventListData.findIndex((x: any) => x.id === socketData.rodeo_event_id)
                if (findEventIndex >= 0) {
                    getEventListData[findEventIndex].currently_ongoing = socketData.status
                }
            }
            return {
                ...state,
                getEventList: getEventListData && getEventListData.length ? getEventListData.map((x: any) => Object.assign(x)) : []
            }
        default:
            return state;
    }
}

export default eventReducer;
