import { combineReducers } from 'redux';
import loaderReducer from './common/loaderReducer'
import userReducer from './user/userReducer'
import eventReducer from './event/eventReducer';


const rootReducer = combineReducers({
  loader: loaderReducer,
  user: userReducer,
  event: eventReducer,
})

export default rootReducer