import React from 'react';
import { Modal } from 'react-bootstrap';

interface CouponModalProps {
    onClose: (success: any) => void;
    shouldShow: boolean;
    image: any;
}


export default function CouponModal({ onClose, shouldShow, image }: CouponModalProps) {

    return (
        <React.Fragment>
            <Modal
                show={shouldShow}
                backdrop="static"
                keyboard={false}
                className="dark-modal"
                centered
                contentClassName='custom-modal'
            >
                <Modal.Body bsPrefix={'main-container'}>
                    <div className="modal-body">
                        <img src={image} style={{width:"100%"}}/>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {/* <div className="btn-wrap mb-3 ">
                        <button type="button"
                            className="rkmd-btn btn-lg btn-orange ripple-effect w-100 mt-3"
                            onClick={onClose}>
                            Close
                        </button>
                    </div> */}
                    <div className="btn-wrap mb-3">
                        {/* <button type="button"
                            className="rkmd-btn btn-lg btn-orange ripple-effect w-100 mt-3"
                            onClick={onClose}>
                            Download
                        </button> */}
                        <a
                            href={image}
                            className="rkmd-btn btn-lg btn-orange ripple-effect w-100 mt-3"
                            download
                            // onClick={onClose}
                            target="_blank"
                        >
                            Download
                        </a>
                    </div>
                    <div className="btn-wrap mb-3">
                        <button type="button"
                            className="rkmd-btn btn-lg btn-red ripple-effect w-100 mt-3"
                            onClick={onClose}>
                            Close
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    )

}