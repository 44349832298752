import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import useSocket from 'use-socket.io-client';
import { useAppUserDetailsSelector } from 'src/_common/hooks/selectors/userSelector';
import { useAppEvenListSelector, useAppRodeoEventLoginRequiredSelector } from 'src/_common/hooks/selectors/eventSelector';
import { CRYPTO_SECRET_KEY, INSIDERODEO_EVENT_STORAGE, paginate, PER_PAGE, SELECTED_EVENT_STORAGE, SOCKET_CHANNEL, SOCKET_URL, STORAGE, URLS } from 'src/_config';
import { useEventApi } from 'src/_common/hooks/actions/event/appEventApiHook';
import { toast } from 'react-toastify';
import clsx from 'clsx';
import { useAppEventAction } from 'src/_common/hooks/actions/event/appEventActionHook';
import { useUserApi } from 'src/_common/hooks/actions/user/appUserApiHook';
import { useAppUserAction } from 'src/_common/hooks/actions/user/appUserActionHook';
// import Pagination from 'react-js-pagination';

const Cryptr = require('cryptr');
const cryptr = new Cryptr(CRYPTO_SECRET_KEY);
const queryString = require('query-string');

export default function RodeoEventPage() {

    const eventApi = useEventApi()
    const history = useHistory()
    const location = useLocation()
    const eventAction = useAppEventAction()
    const userDetails = useAppUserDetailsSelector()
    const eventListSelector = useAppEvenListSelector()
    const loginReq = useAppRodeoEventLoginRequiredSelector()
    const [eventListData, setEventListData] = useState<any[]>([])
    const [page, setPage] = useState<number>(1)
    const [total, setTotal] = useState<number>(0)
    const [isFetching, setIsFetching] = useState(false);
    const [isPageChange, setIsPageChange] = useState<boolean>(false)
    const userApi = useUserApi()
    const userAction = useAppUserAction()

    const isInsideRodeoTokenExists = localStorage.getItem(INSIDERODEO_EVENT_STORAGE)

    const goToGameListingPage = (e: any, event: number) => {
        e.preventDefault();
        const eventId = cryptr.encrypt(event)
        localStorage.setItem(SELECTED_EVENT_STORAGE, eventId);
        // let lr = eventListSelector.list.find((x:any)=>x.id==event).login_required
        // eventAction.updateLoginRequired(lr)
        // console.log(loginReq)
        // eventAction.resetPickIds()
        history.push(`/event/${eventId}`)
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    useEffect(() => {
        if (!isFetching) return;
        fetchMoreListItems();
    }, [isFetching]);

    // Below function excute the scroll operation text show or hide
    function handleScroll() {
        if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight || isFetching) return;
        if (eventListData.length === total) return; //Disable scrolling state if total event data is equal to total data
        setIsFetching(true);
    }

    //Get event List
    const getEventList = () => {
        var params = {
            page: page,
            perPage: PER_PAGE
        }

        eventApi.callGetEventList(params, (message: string, resp: any) => {
            if (resp && resp.list && resp.list && resp.list.length) {
                setEventListData((prevState => ([...prevState, ...paginate(resp.list, PER_PAGE, page)])))
                setTotal(resp.list.total ? parseInt(resp.list.total) : 0)
                setPage(page + 1)
                setIsFetching(false);
            } else {
                setEventListData((prevState => ([...prevState])))
                setIsFetching(false);
            }
        }, (message: string) => {
            toast.error(message)
        })
    }

    //On page scroll load more data
    // Event api call once again
    const fetchMoreListItems = () => {
        setTimeout(() => {
            getEventList()
        }, 2000);
    }

    //First Time call When component is load
    useEffect(() => {
        getEventList()
    }, [])

    // const handlePageChange = (inputPage: number) => {
    //     console.log("Page change call");
    //     if (page !== inputPage) {
    //         setIsPageChange(true)
    //         setPage(inputPage)
    //     }
    // }

    //Pagination page change api call
    // useEffect(() => {
    //     if (isPageChange) {
    //         getEventList()
    //     }
    // }, [page, perPage])

    useEffect(() => {
        const parsedHash = queryString.parse(location.hash);
        console.log(parsedHash)
        if(parsedHash&&parsedHash.userToken&&parsedHash.userToken!='null')
        {
          userAction.resetGuestUserId()
          localStorage.setItem(STORAGE, parsedHash.userToken);
          userApi.callGetMe((message: string, resp: any) => {
          }, (message: string, resp: any) => {
              history.push(URLS.HOME)
              userAction.logout()
          })
        }
        if (parsedHash && parsedHash.token) {
            localStorage.setItem(INSIDERODEO_EVENT_STORAGE, parsedHash.token)
        //     if (userDetails) {
                history.push(`/event/${localStorage.getItem(INSIDERODEO_EVENT_STORAGE)}`)
        //     } else {
        //         history.push(URLS.LOGIN)
        //     }
        } else if (isInsideRodeoTokenExists) {
            history.push(`/event/${localStorage.getItem(INSIDERODEO_EVENT_STORAGE)}`)
        } else {
            history.push(URLS.HOME)
        }

    }, [])

    const [socket] = useSocket(SOCKET_URL, {
        autoConnect: false,
    });

    useEffect(() => {

        if (userDetails) {
            socket.connect();

            //Status Change Socket
            socket.on(SOCKET_CHANNEL.START_EVENT, (changeEventStatus: any) => {
                eventAction.updateEventGlowingStatus(changeEventStatus)
            });
        }

        return () => {
            socket.disconnect()
        }
    }, [socket])

    return (
        <React.Fragment>
            <h2 className="page-title text-left">
                <span>Rodeo </span>Events</h2>
            <hr className="color-white" />
            <ul className="list-style">
                {
                    eventListSelector && eventListSelector.list && eventListSelector.list.length ? eventListSelector.list.map((event: any) => (
                        <li key={event.id} className={
                            clsx({
                                "glow": event.currently_ongoing == 1
                            })
                        }>
                            <Link to="" onClick={(e) => goToGameListingPage(e, event.id)} title={event.title}
                                className="ripple-effect">
                                <span className="r-event-img">
                                    <img src={event.avatar && event.avatar.thumb ? event.avatar.thumb : 'images/no-image.png'} alt={event.title} />
                                </span>
                                <span className="r-event-info">
                                    <span className="d-inline-block r-event-title">{event.title}</span>
                                    <span className="d-block r-location">{event.location ? event.location : '...'}</span>
                                </span>
                            </Link>
                        </li>
                    )) :
                        <div className="no-text text-white text-center">
                            No events available
                        </div>
                }
            </ul>
            {
                isFetching &&
                <div style={{ color: '#fce006' }}>
                    <p>Loading more events. Please wait... </p>
                </div>
            }
            {/* <div className="pagination-block">
                <Pagination
                    activePage={page}
                    itemsCountPerPage={PER_PAGE}
                    totalItemsCount={total}
                    pageRangeDisplayed={5}
                    itemClass="page-item"
                    linkClass="page-link"
                    onChange={handlePageChange}
                />
            </div> */}
            {/* <UpcomingRodeoEventComponent /> */}
        </React.Fragment >
    )
}
