// let apiBaseUrl = 'https://backendrodeo.insiderodeo.com/api/v1/';
// let socketUrl = 'https://backendrodeo.insiderodeo.com:6672';
let apiBaseUrl = 'https://backendrodeo.glohtesting.com/api/v1/';
let socketUrl = 'https://backendrodeo.glohtesting.com:6682';

let appStage = process.env.REACT_APP_STAGE

if (appStage == 'dev') {
  // apiBaseUrl = 'https://backendrodeo.insiderodeo.com/api/v1/';
  // socketUrl = 'https://backendrodeo.insiderodeo.com:6672';
  apiBaseUrl = 'https://backendrodeo.glohtesting.com/api/v1/';
  socketUrl = 'https://irsocketstage.dreamztesting.com:6672/';
} else if (appStage == 'uat') {
  apiBaseUrl = 'https://backendrodeo.insiderodeo.com/api/v1/';
  socketUrl = 'https://backendrodeo.insiderodeo.com:6672';
} else if (appStage == 'prod') {
  apiBaseUrl = 'https://backendrodeo.insiderodeo.com/api/v1/';
  socketUrl = 'https://socket.insiderodeo.com:6672/';
  // apiBaseUrl = 'https://backendrodeo.glohtesting.com/api/v1/';
  // socketUrl = 'https://backendrodeo.glohtesting.com:6682';
}

export const API_BASE_URL = apiBaseUrl
export const SOCKETURL = socketUrl