import React, { useEffect } from 'react';
import Sidebar from 'src/components/common/Sidebar';
import RodeoContestantPage from 'src/components/events/rodeoContestant/rodeoContestantPage';
import { RODEO_PAGE_NAME, APPLICATION_NAME } from 'src/_config';

export default function RodeoContestantContainer() {

    useEffect(() => {
        const prevTitle = document.title
        document.title = `${APPLICATION_NAME} : Riders`
        return () => {
            document.title = prevTitle
        }
    }, [])

    return (
        <React.Fragment>

            <Sidebar page={RODEO_PAGE_NAME.RIDER_LIST} />
            <section className="main-container">
                <RodeoContestantPage />
            </section>
        </React.Fragment>
    )
}
