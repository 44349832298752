import { call, put } from 'redux-saga/effects';
import { ACTIONS, API_URL } from '../_config';
import { CallApi } from './api/callApi';

export function* getEventList(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.EVENT.GET_EVENT_LIST, data);
        if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
            yield put({
                type: ACTIONS.EVENT.GET_EVENT_LIST,
                payload: resp.data.data
            })
            // yield put({
            //     type: ACTIONS.EVENT.RODEO_EVENT_LOGIN_REQUIRED,
            //     payload: resp.data.data
            // })
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getEventPerformanceList(action: any): any {
    try {
        const data = action.payload;
        const rodeo_event_id = data.rodeo_event_id;
        delete data.rodeo_event_id;
        const resp = yield call(CallApi.GET, API_URL.EVENT.GET_EVENT_PERFORMANCE+`/${rodeo_event_id}`, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
            yield put({
                type: ACTIONS.EVENT.GET_EVENT_PERFORMANCE,
                payload: resp.data.data.list
            })
            // yield put({
            //     type: ACTIONS.EVENT.GET_STATUS_MESSAGE,
            //     payload: resp.data.data.status
            // })
            yield put({
                type: ACTIONS.EVENT.RODEO_EVENT_LOGIN_REQUIRED,
                payload: resp.data.data.rodeo_event_dtls.login_required
            })
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getEventContestants(action: any): any {
    try {
        const data = action.payload;
        const rodeo_event_id = data.rodeo_event_id;
        delete data.rodeo_event_id;
        const performance_id = data.performance_id;
        delete data.performance_id;
        const resp = yield call(CallApi.GET, API_URL.EVENT.GET_EVENT_CONTESTANTS + `/${rodeo_event_id}/${performance_id}`, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
            yield put({
                type: ACTIONS.EVENT.GET_EVENT_CONTESTANTS,
                payload: resp.data.data
            })
            // yield put({
            //     type: ACTIONS.EVENT.GET_STATUS_MESSAGE,
            //     payload: resp.data.data.status
            // })
            // yield put({
            //     type: ACTIONS.EVENT.RODEO_EVENT_LOGIN_REQUIRED,
            //     payload: resp.data.data.rodeo_event_dtls.login_required
            // })
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

// export function* getUpcomingEventList(action: any): any {
//     try {
//         const data = action.payload;
//         const resp = yield call(CallApi.GET, API_URL.EVENT.GET_UPCOMING_EVENT, data);
//         if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
//             action && action.callbackSuccess && action.callbackSuccess(resp.data);
//         } else {
//             action && action.callbackError && action.callbackError(resp.data);
//         }
//     } catch (e) {
//         action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
//     }
// }

// export function* joinEventGame(action: any): any {
//     try {
//         const data = action.payload;
//         const resp = yield call(CallApi.POST, API_URL.EVENT.JOIN_EVENT_GAME, data, true);
//         if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
//             action && action.callbackSuccess && action.callbackSuccess(resp.data);
//         } else {
//             action && action.callbackError && action.callbackError(resp.data);
//         }
//     } catch (e) {
//         action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
//     }
// }

export function* pickContestant(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.EVENT.PICK_CONTESTANT, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
            yield put({
                type: ACTIONS.EVENT.SET_PICK_ID,
                payload: resp.data.data.id
            })
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

// export function* viewLeaderBoard(action: any): any {
//     try {
//         const data = action.payload;
//         const resp = yield call(CallApi.POST, API_URL.EVENT.LEADERBOARD, data, true);
//         if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
//             yield put({
//                 type: ACTIONS.EVENT.LEADERBOARD,
//                 payload: resp.data.data
//             })
//             action && action.callbackSuccess && action.callbackSuccess(resp.data);
//         } else {
//             action && action.callbackError && action.callbackError(resp.data);
//         }
//     } catch (e) {
//         action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
//     }
// }

// export function* tvImageDetails(action: any): any {
//     try {
//         const data = action.payload;
//         const resp = yield call(CallApi.GET, API_URL.TV_IMAGE.DETAILS, data);
//         if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
//             yield put({
//                 type: ACTIONS.TV_IMAGE.DETAILS,
//                 payload: resp.data.data
//             })
//             action && action.callbackSuccess && action.callbackSuccess(resp.data);
//         } else {
//             action && action.callbackError && action.callbackError(resp.data);
//         }
//     } catch (e) {
//         action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
//     }
// }

export function* saveVote(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.EVENT.SAVE_VOTE, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}