import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import useSocket from 'use-socket.io-client';
import clsx from 'clsx';
import { useAppEvenListSelector, useAppEventContestantListSelector, useAppEventGameDetailSelector, useAppNextEventRouteSelector, useAppPickIdsSelector, useAppRodeoEventLoginRequiredSelector } from 'src/_common/hooks/selectors/eventSelector';
import { useEventApi } from 'src/_common/hooks/actions/event/appEventApiHook';
import { useAppEventAction } from 'src/_common/hooks/actions/event/appEventActionHook';
import { useAppUserAction } from 'src/_common/hooks/actions/user/appUserActionHook';
import { useAppGuestUserIdSelector, useAppUserDetailsSelector } from 'src/_common/hooks/selectors/userSelector';
import { CRYPTO_SECRET_KEY, getEventStatusText, removeTrailingZero, CALFSCRAMBLE_NEXT_GAME_IDS, SOCKET_CHANNEL, SOCKET_URL, trimTo, URLS, getAllWordsExceptLastWord, getLastWord } from "src/_config";
import { toast } from "react-toastify";
import ContestantSelectionModal from "../modal/contestantSelectionModal";
import GuestSaveVoteModal from "../modal/guestSaveSelectionModal";

const Cryptr = require('cryptr');
const cryptr = new Cryptr(CRYPTO_SECRET_KEY);

export default function RodeoContestantPage() {

    const eventApi = useEventApi()
    const history = useHistory()
    const eventAction = useAppEventAction()
    const userAction = useAppUserAction()
    const userDetails = useAppUserDetailsSelector()
    const fromRoute = useAppNextEventRouteSelector()
    const { eventId, gameId } = useParams<any>();
    const e_id: number = parseInt(cryptr.decrypt(eventId));
    const g_id: number = parseInt(cryptr.decrypt(gameId));
    const eventContestantListSelector = useAppEventContestantListSelector()
    const [showContestantSelectionModal, setContestantSelectionModal] = useState<boolean>(false)
    const [showGuestSaveVoteModal, setShowGuestSaveVoteModal] = useState<boolean>(false)
    const [selectedContestant, setSelectedContestant] = useState<any>()
    const eventListSelector = useAppEvenListSelector()
    const guestUserId = useAppGuestUserIdSelector()
    const [nextGameIds, setNextGameIds] = useState<any>([])
    const pick_id = useAppPickIdsSelector()
    const [nextGameArrayIndex, setNextGameArrayIndex] = useState<any>()
    // const loginReq = useAppRodeoEventLoginRequiredSelector()
    const [loginReq, setLoginReq] = useState<any>(0)

    const submitVoteForContestant = () => {
        const params:any = {
            performance_id: g_id,
            rodeo_event_id: e_id,
            contestant_id: selectedContestant && selectedContestant.id,
        }
        if(guestUserId)
        {
            params['guest_user_id']=guestUserId
        }
        console.log(params)
        eventApi.callPickContestant(params, (message: string, resp: any) => {
            if (resp) {
                closeContestantSelectionModal()
                // userAction.guestUserId(resp)
                getEventContestants()
            }
        }, (message: string) => {
            toast.error(message)
        })
    }

    // Vote Rider Modal relaed functionality start here
    const openContestantSelectionModal = (e: any, contestant: any) => {
        console.log(eventContestantListSelector.performance_info.pick_status)
        e && e.preventDefault()
        if (eventContestantListSelector && eventContestantListSelector.list && eventContestantListSelector.list.find((x:any)=>x.ispicked)) {
            return;
        }
        else if (eventContestantListSelector && eventContestantListSelector.performance_info && eventContestantListSelector.performance_info.pick_status==0) {
            return;
        }
        else if (eventContestantListSelector && eventContestantListSelector.performance_info && eventContestantListSelector.performance_info.performance_start==1) {
            return;
        }
        else if (contestant.is_complete == 1) {
            return;
        }
        else {
            if (!showContestantSelectionModal) {
                setContestantSelectionModal(true)
                setSelectedContestant(contestant)
            }
        }
    }
    const closeContestantSelectionModal = () => {
        if (showContestantSelectionModal) setContestantSelectionModal(false)
    }

    const onSuccessContestantSelectionVote = (status: boolean) => {
        if (status) {
            submitVoteForContestant()
        }
    }
    // Modal functionality end here

    const backToPerformanceListingPage = (e: any) => {
        e.preventDefault();
        history.push(`/event/${eventId}`)
    }

    // const goToLeaderBoardPage = (e: any) => {
    //     e.preventDefault()
    //     history.push(`/result/${eventId}/${gameId}`)
    // }

    // const goToGlobalResultsPage = () => {
    //     history.push(`/result/${eventId}`)
    // }

    const getEventContestants = (guestId?:any) => {
        const params:any = {
            performance_id: g_id,
            rodeo_event_id: e_id,
            game_id: eventListSelector?.game_details.id,
        }
        if(!userDetails)
        {
            if(guestUserId)
            {
                params['guest_user_id']=guestUserId
            }
            // else if(guestId)
            // {
            //     params['guest_user_id']=guestId
            // }
        }
        eventApi.callEventContestantList(params, (message: string, resp: any) => {
            if (resp) { 
                setLoginReq(resp.rodeo_event_dtls.login_required)
            }
        }, (message: string) => {
            toast.error(message)
        })
    }

    useEffect(() => {
        getEventContestants()
    }, [fromRoute])

    
    //Get localstorage game ids
    useEffect(() => {
        if (localStorage.getItem(CALFSCRAMBLE_NEXT_GAME_IDS)) {
            let ids = localStorage.getItem(CALFSCRAMBLE_NEXT_GAME_IDS)?.split(',')
            setNextGameIds(ids)
        }
    }, [])

    //Socket Implementation start here
    const [socket] = useSocket(SOCKET_URL, {
        autoConnect: false,
    });

    useEffect(() => {
        socket.connect()

        socket.on(SOCKET_CHANNEL.COMPLETE_RIDE, (adsPromotionData: any) => {
            console.log(adsPromotionData)
            getEventContestants()
        });
        
        return () => {
            socket.disconnect()
        }
    }, [socket])

    useEffect(() => {

            socket.connect();
    
            socket.on(SOCKET_CHANNEL.START_EVENT, (changeEventStatus: any) => {
                // var pickStatus = changeEventStatus.pick_status;
                if(changeEventStatus && changeEventStatus.pick_status==0)
                {
                    getEventContestants()
                }
                if(changeEventStatus && changeEventStatus.pick_status==1)
                {
                    getEventContestants()
                }
            })
    
            return () => {
                socket.disconnect()
            }
        },[socket])

   

    useEffect(() => {
        if (userDetails || loginReq==0)
        {
            history.push(`/event/${eventId}/${gameId}`)
        }
        else
        {
            history.push(`${URLS.LOGIN}`)
        }
    }, [loginReq])

    return (
        <React.Fragment>
            <h2 className="top-two-btns has-leaderboard">
                <Link to="#" className="back-history" onClick={(e) => backToPerformanceListingPage(e)}>
                    <img src="/images/black-left.svg" alt="" />
                    {
                        eventContestantListSelector && eventContestantListSelector.performance_info ?
                        <>
                            <span>
                                {getAllWordsExceptLastWord(eventContestantListSelector.performance_info.title)}
                            </span>
                            {getLastWord(eventContestantListSelector.performance_info.title)}
                        </> : '...'
                    }
                </Link>
                {/* <Link to="#" className="dual-btn" onClick={(e) => goToLeaderBoardPage(e)}>
                    Results
                </Link> */}
            </h2>
            <hr className="color-white" />
            <div className="top-txt">
                {
                    eventContestantListSelector && eventContestantListSelector.performance_info && eventContestantListSelector.performance_info.pick_status && eventContestantListSelector.performance_info.pick_status == 0 ? 
                    <>
                    <p>Game has not started yet</p>
                    </> : eventContestantListSelector && eventContestantListSelector.performance_info && eventContestantListSelector.performance_info.performance_end == 1?<p>Game Ended</p>:<p>Select your favorite to win</p>
                }
            </div>
            {/* {
                eventGameDetailsSelector && eventGameDetailsSelector.events && eventGameDetailsSelector.events.sponsor ?
                    <div className="sponsor-img-wrap">
                        <img src={
                            eventGameDetailsSelector && eventGameDetailsSelector.events && eventGameDetailsSelector.events.sponsor && eventGameDetailsSelector.events.sponsor.sponsor && eventGameDetailsSelector.events.sponsor.sponsor.original
                        } alt={`Sponsor-${eventGameDetailsSelector && eventGameDetailsSelector.events && eventGameDetailsSelector.events.sponsor ? eventGameDetailsSelector.events.sponsor.title : 'image'}`} />
                    </div>
                    : null
            } */}
            <div className="score-range-wrap rider-list">
                <h4>
                    <span>List of Contestants</span>
                    {/* <span>Vote Received(%)</span>  */}
                </h4>
                <ul className="cons-list">
                    {
                        eventContestantListSelector && eventContestantListSelector.list && eventContestantListSelector.list.length ?
                            eventContestantListSelector.list.map((item: any) => (
                                <li key={item.id} className={
                                    clsx({
                                        "selected": item.ispicked
                                    })
                                }>
                                    {/* <Link to="#"> */}
                                        <span className="name-img" onClick={(e) => openContestantSelectionModal(e, item)}>
                                            <em className="user-img-wrap">
                                                <img src={item && item.avatar && item.avatar.original ? item.avatar.original : '/images/no-image.png'} alt={item.contestant_name} />
                                            </em>
                                            {item.batch_no} {item.contestant_name?'('+item.contestant_name+')':''}
                                        </span>
                                        {item.is_complete ? <span className="count-vote"></span>:null}
                                        {/* <span className={
                                            clsx({
                                                "voted": item.ispicked
                                            })
                                        }> */}
                                            {/* {
                                                item && (eventGameDetailsSelector.events && eventGameDetailsSelector.events.ispicked ? removeTrailingZero(item.vote) : '-')
                                            } */}
                                        {/* </span> */}
                                    {/* </Link> */}
                                </li>

                            ))
                        :
                        <li className="no-rider">
                            No performances added yet!
                        </li>
                    }
                </ul>
            </div>
            {/* {
                nextGameArrayIndex == 0 ?
                    <div className="join-now-btn-wrap">
                        <button type="submit"
                            onClick={goToGlobalResultsPage}
                        >
                            See the most popular picks
                        </button>
                    </div>
                    :
                    <div className="join-now-btn-wrap">
                        <button type="submit"
                            onClick={continueNextEvent}
                        >
                            Continue to next event
                        </button>
                    </div>
            } */}

            <div className="btn-wrap">
                {/* <button className="rkmd-btn btn-lg btn-orange ripple-effect w-100 mt-3">Continue to next event</button> */}
                <button className="rkmd-btn btn-lg btn-orange ripple-effect w-80 mt-3 btn-evnt-list " onClick={(e) => backToPerformanceListingPage(e)}>
                    Back to Performance List
                </button>
            </div>


            {
                showContestantSelectionModal &&
                <ContestantSelectionModal
                    onClose={closeContestantSelectionModal}
                    onSuccess={onSuccessContestantSelectionVote}
                    shouldShow={showContestantSelectionModal}
                    contestantDetails={selectedContestant}
                />
            }

            {/* {
                showGuestSaveVoteModal &&
                <GuestSaveVoteModal
                    onClose={closeGuestSaveVoteModal}
                    onSuccess={onSuccessGuestSaveVoteModal}
                    shouldShow={showGuestSaveVoteModal}
                />
            } */}
        </React.Fragment>
    )
}