// @ts-nocheck
import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { URLS } from './_config';
import requireNoAuth from './_common/hoc/reqNoAuth';
import requireAuth from './_common/hoc/reqAuth';
import reqLayout from './_common/hoc/layout';
import Login from './containers/login';
import MyProfile from './containers/myProfile';
import RodeoEventContainer from './containers/RodeoEvent/rodeoEvent';
import RodeoPerformanceContainer from './containers/RodeoPerformance/rodeoPerformance';
import RodeoContestantContainer from './containers/RodeoContestant/rodeoContestant';
import RodeoGameLeaderBoardContainer from './containers/rodeoLeaderBoard/game-specific/rodeoLeaderBoard';
import RodeoEventLeaderBoardContainer from './containers/rodeoLeaderBoard/event-specific/rodeoEventLeaderBoard';
import AboutUsContainer from './containers/cms/aboutUs/aboutUs';
import LegalContainer from './containers/cms/legal/legal';
import HowToPlayContainer from './containers/cms/howToPlay/howToPlay';
import TermOfUseContainer from './containers/cms/termOfUse/termOfUse';
import RodeoTvImageContainer from './containers/tv-image/tv-image';
import Page404 from './containers/page-404';
import CacheBuster from './CacheBuster';

function App() {

  return (
    <CacheBuster>
      {({ loading, isLatestVersion, refreshCacheAndReload }) => {
        if (loading) return null;
        if (!loading && !isLatestVersion) {
          refreshCacheAndReload();
        }
        return (
          <React.Fragment>
            <Router>
              <Switch>
                <Route exact path={URLS.HOME} component={(RodeoEventContainer)} />
                <Route exact path={URLS.TV_IMAGE} component={(RodeoTvImageContainer)} />
                <Route exact path={URLS.USER.PROFILE} component={requireAuth(MyProfile)} />
                <Route exact path={URLS.CMS.ABOUT_US} component={(AboutUsContainer)} />
                <Route exact path={URLS.CMS.LEGAL} component={(LegalContainer)} />
                <Route exact path={URLS.EVENT.PERFORMANCES} component={reqLayout(RodeoPerformanceContainer)} />
                <Route exact path={URLS.EVENT.CONTESTANT_LIST} component={reqLayout(RodeoContestantContainer)} />
                {/* <Route exact path={URLS.EVENT.LEADER_BOARD.EVENT} component={reqLayout(RodeoEventLeaderBoardContainer)} />
                <Route exact path={URLS.EVENT.LEADER_BOARD.GAME} component={reqLayout(RodeoGameLeaderBoardContainer)} /> */}
                <Route exact path={URLS.LOGIN} component={requireNoAuth(Login)} />
                <Route exact path={URLS.CMS.TERM_OF_USE} component={(TermOfUseContainer)} />
                <Route exact path={URLS.CMS.HOW_TO_PLAY} component={(HowToPlayContainer)} />
                <Route path="*" component={reqLayout(Page404)} />
              </Switch>
            </Router>
          </React.Fragment>
        );
      }}
    </CacheBuster>
  );
}

export default App;
